import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import notFoundImg from "../images/undraw_page_not_found_su7k.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Nenalezeno" />
      <div>
        <img
          alt="Chyba 404"
          className="block mx-auto sm:w-1/2 my-12"
          src={notFoundImg}
        />
        <a className="block mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-32 rounded text-center" href="/">
          Rozcestník
        </a>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
